.container {
   position: fixed;
   /* display: none; */
   flex-direction: column;
   top: 0;
   left: 0;
   height: 100%;
   max-height: 100%;
   min-width: 100vw !important;
   max-width: 100vw !important;
   padding-top: 18px;
   overflow: hidden;
   z-index: 90;
   transform: scale(0, 1);
   transform-origin: left;
   transition: all 0.2s ease;
   background: var(--iq-color-background-warm-gradient);
}

.slider-panel-button {
   display: none;
   position: fixed;
   top: 50%;
   left: 0;
   z-index: 10;
   transform: rotate(-90deg) translateY(-239%) translateX(0)
      translate3d(0, 0, 0);
   margin: 0;
   padding: 0.5em 2em 0.5em;
   font: var(--iq-color-accent-gradient);
   justify-content: center;
   border-radius: 0 0 25px 25px;
   background: var(--iq-color-background-warm-gradient);
   color: var(--iq-color-foreground);
   text-transform: uppercase;
}

/* .close-slider-panel-button {
   color: var(--iq-color-background-warm-gradient);
   background: var(--iq-color-foreground);
} */

/* Hover turned Off for touch and stylus */
body[class*="can-hover"] .slider-panel-button:hover {
   background: var(--iq-color-accent);
   transform: rotate(-90deg) translateY(-225%) translateX(0%)
      translate3d(0, 0, 0);
}

.open {
   transform: scale(1, 1);
}
.container > div {
   min-width: 100% !important;
   min-height: 100% !important;
   max-width: 100% !important;
   max-height: 100% !important;
   border-radius: 0 !important;
}

.container div[class*="slide-button-wrap"] {
   max-width: 28em;
}

@media only screen and (max-width: 768px) {
   .slider-panel-button {
      display: flex;
      /* min-width: min-content; */
   }
}
