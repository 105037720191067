/* .input-container {
  flex-grow: 1;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: left;
  min-height: 2em;
  flex-direction: column;
  padding: 1em;
  /* flex-basis: calc(25% - 4em); */
/* height: 100%; */
/* } */
.input-container {
   flex-grow: 1;
   margin-top: 1rem;
   margin-bottom: 1rem;
   display: flex;
   justify-content: left;
   min-height: 2em;
   /* flex-direction: column; */
   padding: 1em;
   /* flex-basis: calc(25% - 4em); */
   height: 100%;
   flex-basis: 50%;
   max-width: 100%;
   flex-wrap: wrap;
}

.input-container {
   margin-top: 2rem;
   margin-bottom: 1rem;
   display: flex;
   justify-content: left;
   flex-basis: fit-content;
   height: 100%;
   max-width: 100%;
   flex-wrap: wrap;
   width: min-content;
   min-width: min-content;
   width: 100%;
   padding: 1em 2em;
   /* box-shadow:
      3px 3px 10px -4px white inset,
      -3px -3px 10px -4px #000000 inset; */
}

.input-container > label {
   flex-basis: 100%;
   font-variant: small-caps;
   margin: auto;
   font-size: 1.6rem;
   letter-spacing: var(--iq-spacing-heading);
   z-index: 1;
   margin-top: -0.5em;
   margin: 0;
   line-height: 1em;
   text-align: left;
   padding: 0 1em;
   margin-bottom: 1em;
}

.input-container > label {
   width: fit-content;
   flex-basis: min-content;
   position: absolute;
   top: 0;
   left: 0;
   transform: translate(2em, -100%);
   background: var(--iq-color-foreground);
   color: var(--iq-color-background);
   padding: 0 2em 0.25em;
   border-radius: 50px 50px 0 0;
   /* transform: rotate(-45deg); */
   font-size: 1em;
   box-shadow:
      inset 0px 3px 7px -4px var(--iq-color-accent-dark),
      inset 0px 0px 12px -19px var(--iq-color-accent-dark);
}

body[class*="largeForms"] .input-container > label {
   position: relative;
   top: 0;
   left: 0;
   transform: none;
   background: inherit;
   padding: 0 1em;
   color: inherit;
   box-shadow: none;
   font-size: initial;
   flex-basis: 100%;
}

.input-container .input-wrap {
   display: flex;
   width: 100%;
   min-height: min-content;
   text-align: left;
   max-width: 16em;
   max-height: 3em;
   min-width: min-content;
   width: fit-content;
   overflow: hidden;
   font-size: 1.4rem;
}

.input-container button {
   position: relative;
   font-weight: 700;
   box-shadow:
      3px 3px 7px -4px #ffffffdb inset,
      -3px -3px 7px -4px black inset;
   padding: 0 1em;
   border-radius: 6.125px;
   transition: 0.1s all ease;
   /* transform: scale(1, 1.125) translateX(-1em); */
   background: var(--iq-color-accent);
   color: var(--iq-color-foreground);
   border: none;
   margin: auto 1em;
}

.required-input-error-text {
   width: 100%;
}

/* .display-row {
  flex-basis: 33.33%;
} */

input {
   background: var(--iq-color-accent);
   color: var(--color-group-one-white);
   font-weight: 700;
   padding: 0 1em;
   box-shadow:
      3px 3px 7px -4px white inset,
      -3px -3px 7px -4px black inset;
}

input[value=""],
input:focus,
textarea {
   background: var(--iq-color-background);
   box-shadow:
      3px 3px 7px -4px #000000 inset,
      -3px -3px 7px -4px #ffffff inset;
   color: var(--iq-color-foreground);
}

.input-container input[type="text"],
.input-container input[type="email"],
.input-container input[type="password"] {
   flex-basis: 100%;
   flex-grow: 1;
   min-height: min-content;
   padding: 0.5em 2em;
   font-size: 12px;
   font-weight: 700;
   border: none;
   margin-bottom: 0.25em;
   /* border-radius: 20px; */
   border-radius: 50px;
}

.input-container select,
.input-container option {
   padding: 0.5em;
   text-align: center;
   color: var(--iq-color-foreground);
   background: var(--iq-color-background-warm-gradient);
   /* padding-bottom: 2.5em !important; */
}

.input-container option {
   text-align: center;
}

.required-input-error {
   border: 3px inset var(--iq-color-accent-2) !important;
   /* color: var(--iq-color-accent) !important; */
}
.required-input-error-text {
   color: var(--iq-color-accent-2) !important;
   font-size: 0.75em;
   letter-spacing: 0.25em;
   line-height: 0.5em;
}

.required-input {
   border: 5px solid var(--iq-color-accent) !important;
}

.input-container textarea,
.input-container select,
.input-container input {
   border-radius: 50px;
}

.input-container textarea {
   min-width: 100%;
   max-height: 100%;
   min-height: 3em;
   resize: vertical;
   padding: 1em 2em 0;
}

.input-container input[type="url"] {
   height: 3em;
   width: 100%;
   border-radius: 50px;
}

::placeholder {
   opacity: 0.5;
   font-weight: 100;
   font-style: italic;
}

/* *** Checkbox Styles *** */
.input-container input[type="checkbox"] + label,
.input-container .UserAdded.input-wrap:before {
   flex-grow: 1;
   max-width: calc(100% - 2em);
   font-size: calc(0.5em + 0.3vw);
   line-height: 1.25em;
   font-weight: 700;
   border: none;
   padding: 0.75em 0.5em 0.75em 0.5em;
   margin: auto;
   min-width: min-content;
   z-index: 2;
   text-align: center;
   text-transform: capitalize;
}

.input-container input[type="checkbox"] {
   position: relative;
   display: flex;
   -webkit-appearance: none;
   background-color: #fafafa;
   border: 1px solid #cacece;
   box-shadow:
      2px 2px 5px -1px rgb(255 255 255 / 50%),
      inset 3px 3px 5px -1px hsl(0deg 0% 0% / 50%);
   box-shadow:
      inset 2px 2px 5px -1px rgb(255 255 255 / 50%),
      inset -1px -1px 5px -1px hsl(0deg 0% 0% / 50%);
   padding: 9px;
   border-radius: 3px;
   display: inline-block;
   position: relative;
}

.input-container input[type="checkbox"]:active,
.input-container input[type="checkbox"]:checked:active {
   box-shadow:
      0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.input-container input[type="checkbox"]:checked:after {
   content: "\2714";
   font-size: 2em;
   position: absolute;
   font-weight: 900;
   top: 50%;
   left: 0px;
   max-width: 110%;
   min-width: 110%;
   min-height: 110%;
   color: var(--iq-color-accent);
   background: var(--iq-color-foreground);
   box-shadow: none;
   transform: scale(1.5) translate(12%, -40%);
   text-shadow: 0 0 5px var(--iq-color-foreground);
}

/* .input-container input[type="checkbox"]:after {
  font-size: 28px;
  position: absolute;
  font-weight: 900;
  top: 50%;
  left: 0px;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  color: var(--iq-color-background);
  background: var(--iq-color-foreground-gradient);
  text-shadow: 0 0 1px #000000, 0 0 3px #ffffff;
  transform: translateY(-50%);
  box-shadow: inset -2px -2px 7px -5px black, inset 3px 3px 7px -5px white;
  background: transparent;
} */
/****************/

/* *** App-Specific Fields *** */
body[class*="largeForms"] .name {
   font-size: 18px;
}

.name,
.input-company {
   flex-basis: 100%;
   flex-grow: 1;
   padding: 0.5em;
   font-weight: 700;
   border: none;
   text-align: center;
}

.input-company input {
   height: 3em;
   width: 100%;
   border-radius: 50px;
}

.input-container.name input,
.input-container.input-company input {
   text-align: center;
   font-size: 1em;
}

body:not([class*="largeForms"]) .input-container.name input {
   line-height: 1.75em;
}

.input-container.input-company select {
   text-align: center;
   font-size: 1em;
   width: 100%;
   min-height: min-content;
}

.input-container.input-color,
.input-container.input-precision {
   justify-content: center;
}
.input-container.input-color .input-wrap,
.input-container.input-precision .input-wrap {
   /* flex-basis: 33.3%; */
   flex-basis: auto;
   margin: 0 auto 0.5em;
}

body:not([class*="largeForms"]) .input-container.input-precision .input-wrap {
   text-align: left;
   width: 100%;
}

body:not([class*="largeForms"])
   .input-container.input-precision
   input[type="checkbox"]
   + label,
body:not([class*="largeForms"])
   .input-container.input-functions
   input[type="checkbox"]
   + label {
   text-align: left;
}

/* body:not([class*='largeForms'])
  .input-container.input-functions
  [data-group*='SPACER-']
  input[type='checkbox']
  + label {
  text-align: center;
} */

body:not([class*="largeForms"]) .input-container input[type="checkbox"] + label,
body:not([class*="largeForms"]) .input-container input[type="radio"] + label {
   box-shadow: none;
}
.photoURL-wrap {
   width: 100%;
   text-align: left;
}

.photoURL-wrap button {
   background: var(--iq-color-accent-2);
   color: var(--iq-color-foreground);
   opacity: 0.75;
   padding: 0.25em 1em;
   border-radius: 50px;
}

.input-container.productURL,
.input-container.photoURL {
   flex-basis: 100%;
}

.input-container.photoURL > label > span {
   max-width: 5em;
   line-height: 1.5em;
   overflow: hidden;
   display: inline-block;
}

.input-container.productURL a,
.input-container.photoURL a {
   /* font-size: 0.75em; */
   letter-spacing: 0.125em;
   display: block;
   transform-origin: left;
   transition: 0.3s all ease;
}

.input-container.productURL a {
   transform-origin: center;
}

.input-container.productURL a:hover,
.input-container.photoURL a:hover {
   transform: scale(1.125, 1) translateX(-3.25%);
}

.input-favorite,
.input-container.input-rating,
.input-container.input-status,
.input-container.input-oversampling {
   /* width: 11em; */
   flex-grow: 1;
   /* max-width: 17em; */
   justify-content: space-evenly;
   text-align: center;
}
.input-container.input-status select,
.input-container.input-status text {
   width: 100%;
}

.input-container.input-status select {
   font-size: 2em;
}

.input-container.input-status input {
   text-align: center;
   /* Not allowing status direct entry at this time */
   display: none;
}

.input-favorite > .input-wrap {
   max-width: 50%;
   margin: 0 auto;
}

.input-rating .input-wrap {
   width: fit-content;
   margin: 0 auto;
   flex-direction: column-reverse;
   align-items: center;
   justify-content: center;
}

.input-oversampling .input-wrap,
.input-favorite .input-wrap {
   margin: 0 auto;
   align-items: center;
   justify-content: center;
}

.input-container.notes {
   flex-basis: 100%;
   min-height: 100%;
   flex-grow: 1;
   text-align: center;
}

/* .input-container._id,
.input-container.identifier,
.input-container.masterlibraryid {
  display: none;
} */

.input-container._id,
.input-container.identifier,
.input-container.id {
   opacity: 0.5;
   transition: 0.3s all ease;
   transition-delay: 0.5s;
   pointer-events: none;
   flex-basis: 25%;
}

/* .input-container.masterLibraryID {
  padding-bottom: 5.5em;
} */

.input-container.masterLibraryID:after {
   content: "\2191  Change this only if it does not match the VST/AU file name \2191";
   position: relative;
   display: block;
   opacity: 0.5;
   /* top: 8.5em;
  left: 0; */
   width: 100%;
   max-width: 100%;
   /* height: 100%; */
   line-height: 1em;
   /* padding-top: 0.5em; */
   font-size: 10px;
}

/* Special Styling for Functions & Color */

.input-container.input-container.input-functions .input-wrap,
.input-container.input-container.input-color .input-wrap {
   position: relative;
   min-height: -moz-fit-content;
   min-height: fit-content;
   flex-wrap: wrap;
   padding: 0.75 0;
   max-height: none;
   width: 100%;
   min-width: min-content;
   max-width: 100%;
   box-shadow:
      inset 2px 2px 3px -2px black,
      inset -2px -2px 3px -2px white;
   box-shadow:
      inset 5px 5px 5px -5px rgb(255 255 255 / 50%),
      inset -5px -5px 5px -4px hsl(0deg 0% 0% / 50%);
   overflow: visible;
   flex-basis: 18%;
   font-size: 10px;
   min-height: 2.5em !important;
   height: 100%;
   max-height: 100% !important;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 0;
   transition: 0s all linear;
   opacity: 0.75;
}

.input-container.input-container.input-functions .input-wrap:hover,
.input-container.input-container.input-color .input-wrap:hover {
   transform: scale(1, 1.125);
   z-index: 10;
   box-shadow:
      0 0 18px 0 #ffd90080,
      inset 2px 2px 5px -5px rgba(255, 255, 255, 0.5),
      inset -2px -2px 5px -4px hsla(0, 0%, 0%, 0.5);
   opacity: 1;
   z-index: 3;
}

.input-container.input-container.input-functions
   .input-wrap:has(input[type="checkbox"]:checked),
.input-container.input-container.input-color
   .input-wrap:has(input[type="checkbox"]:checked) {
   transform: scale(1);
   /* border-radius: 0; */
   opacity: 1;
   z-index: 2;
}

.input-container.input-container.input-color
   .input-wrap:has(input[type="checkbox"]) {
   border-radius: 50px;
   min-width: 11em;
   flex-grow: 1;
   margin: 0.125em 0;
}

body[class*="largeForms"]
   .input-container.input-container.input-color
   .input-wrap:has(input[type="checkbox"]) {
   border-radius: 0;
}

.input-container.input-container.input-color .input-wrap {
   flex-basis: 25%;
   height: fit-content;
}

.input-container.input-container.input-functions {
   flex-basis: 100%;
   justify-content: center;
   display: grid;
   grid-template-columns: repeat(5, 20%);
   /* grid-auto-flow: column; */
   grid-auto-flow: column;
   /* grid-auto-rows: 3em; */
   grid-auto-rows: 2.5em;
   /* grid-gap: 0.5em; */
   grid-auto-rows: minmax(1em, auto);
}

.input-container.input-container.input-color {
   display: flex;
   justify-content: center;
   align-items: center;
}

.input-container.input-functions input[type="checkbox"],
.input-container.input-color input[type="checkbox"] {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: transparent;
   color: inherit;
   /* background: linear-gradient(
    0deg,
    rgb(0 0 0 / 30%) 0%,
    rgb(0 0 0 / 19%) 13%,
    hsl(0deg 0% 100% / 0%) 30%,
    rgb(255 255 255 / 58%) 76%,
    #fafafa8f 99%,
    #000000b3 100%
  ); */
   /* border: 2px outset #4d4d4d; */
   border: none;
   left: 50%;
   top: 50%;
   margin: 10px;
   transform: translate(calc(-50% - 10px), calc(-50% - 10px));
   border-radius: inherit;
}

body .input-container.input-functions input[type="checkbox"]:after,
body .input-container.input-color input[type="checkbox"]:after {
   content: "";
   background: #00000040;
   background: hsl(0deg 0% 0% / 14%);
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border-radius: inherit;
}

body .input-container.input-functions [data-group^="SPACER"] input:after,
body .input-container.input-color [data-group^="SPACER"] input:after {
   content: none !important;
}
.input-container.input-functions input[type="checkbox"]:checked,
.input-container.input-color input[type="checkbox"]:checked {
   box-shadow: none;
   min-width: 0;
   min-height: 0;
   background: radial-gradient(#fff4b380, transparent);
   /* border: 3px inset #fff4b380; */
   transition: 0s all ease;
}

.input-container.input-functions input[type="checkbox"]:checked + label,
.input-container.input-color input[type="checkbox"]:checked + label {
   /* color: #858585 !important; */
   /* color: var(--iq-color-foreground) !important; */
}

.input-container.input-functions input[type="checkbox"]:checked:after,
.input-container.input-color input[type="checkbox"]:checked:after {
   background: none;
   box-shadow: none;
   transform: none;
   min-width: auto;
   min-height: auto;
}

.input-container.input-functions input[type="checkbox"] + label,
.input-container.input-color input[type="checkbox"] + label,
.input-container .UserAdded.input-wrap:before {
   padding: 0;
   /* font-size: 1.25em; */
   pointer-events: none;
   color: inherit;
   opacity: 0.8;
   text-transform: uppercase;
}

.input-container.input-functions label,
.input-container.input-color label {
   grid-row: 1;
   grid-column: 1 / span 5;
}

.input-container.input-functions input,
.input-container.input-color input {
   grid-row: 19;
   grid-column: 1 / span 5;
}

.input-container.input-functions input[type="checkbox"]:checked + label,
.input-container.input-color input[type="checkbox"]:checked + label {
   text-shadow: 0 0 30px #e3bd26;
   color: inherit;
   opacity: 1;
}

.input-container.input-color input[type="checkbox"]:checked + label {
   /* opacity: 1; */
   background: radial-gradient(#f2e6b7b5, transparent);
}

.input-container.input-functions .input-wrap:before,
.input-container.input-color .input-wrap:before {
   width: 100%;
   flex-basis: 100%;
   font-size: 0.9em;
   /* opacity: 0.5; */
   letter-spacing: 0.25em;
   margin-bottom: 0.5em;
   font-weight: 700;
   margin: 0;
   text-align: center;
   position: absolute;
   top: 2em;
}

.input-container .UserAdded.input-wrap:before {
   width: 100%;
   top: 0;
   transform: scale(0.75) translateY(150%);
   color: var(--color-group-one-white);
}

[data-group^="SPACER"],
[data-group^="SPACER"] * {
   background: transparent;
   color: transparent;
   box-shadow: none;
   text-align: center;
   border: none !important;
   border-radius: 0px !important;
   width: 100%;
   min-width: 100% !important;
   pointer-events: none !important;
   box-shadow: none !important;
   padding: 0 !important;
   align-items: center;
}

[data-group^="SPACER"] {
   /* border: 1px inset !important; */
   box-shadow: inset 0 0 10px 0 !important;
   text-shadow: 0 0 3px;
   padding: 0.5em !important;
   border-radius: 50px !important;
   box-shadow: none !important;
   /* background: var(--iq-color-foreground) !important; */
   background: inherit !important;
   box-shadow: inset 0 0 53px -22px !important;
   /* border-radius: 50px !important; */
   /* overflow: hidden !important; */
   box-shadow:
      0 15px 18px -11px,
      0 -10px 18px -11px,
      inset 0px -14px 11px -16px,
      inset 0px 5px 8px -7px !important;
   /* border: 1px dotted !important; */
   opacity: 0.7 !important;
}

[data-group^="SPACER"] input:after {
   background: transparent;
   color: transparent;
   box-shadow: none;
   text-align: center;
   border: 1px inset gray !important;
   border-radius: inherit;
   width: 100%;
   min-width: 100% !important;
   pointer-events: none !important;
   box-shadow:
      inset -2px -2px 7px -5px white,
      inset 3px 3px 7px -5px black !important;
}

[data-group^="SPACER"] label,
.input-container .UserAdded.input-wrap:before {
   font-size: calc(0.9em + 0.1vw) !important;
   text-shadow: 0 0 10px;
}

[data-group*="Category"] {
   color: #1a1a1d;
   background: var(--color-group-one-white);
   grid-column: 1 / span 1;
   /* grid-row: 2 / span3; */
   min-width: 100% !important;
}

.input-color [class*="light_atmosphere"] {
   color: #1a1a1d;
   background: var(--color-group-one-white);
}
.input-color [class*="modern"] {
   color: var(--color-group-one-white);
   background: #ff1515;
}

/* [data-group="SPACER-Category"] {
  background: var(--iq-color-background-warm-gradient);
  color: var(--iq-color-foreground);
  grid-column: 1 / span 4;
  grid-row: 1;
} */
[data-group*="SPACER-Category"] {
   /* background: #1a1a1d; */
   color: inherit;
}

[data-group*="Dynamics"],
.input-color [class*="70_s"] {
   background: var(--color-group-one-2);
   color: var(--color-group-one-2-text);
   grid-column: 3;
}

[data-group*="SPACER-Dynamics"] {
   color: var(--color-group-one-2);
   /* background: var(--color-group-one-black); */
   grid-column: 3;
}

[data-group*="FrequencyControl"],
.input-color [class*="european"] {
   background: var(--color-group-one-1);
   color: var(--color-group-one-1-text);
   grid-column: 2;
}
[data-group*="SPACER-FrequencyControl"] {
   color: var(--color-group-one-1);
   /* background: var(--color-group-one-black); */
   grid-column: 2;
}

[data-group*="Saturation"],
.input-color [class*="extreme"] {
   background: var(--color-group-one-3);
   color: var(--color-group-one-3-text);
   grid-column: 3;
}
[data-group*="SPACER-Saturation"] {
   color: var(--color-group-one-3);
   /* background: var(--color-group-one-black); */
   grid-column: 3;
}

[data-group*="TimeandSpace"],
.input-color [class*="sci_fi"] {
   background: var(--color-group-one-9);
   color: var(--color-group-one-9-text);
   grid-column: 4;
}
[data-group*="SPACER-TimeandSpace"] {
   color: var(--color-group-one-9);
   /* background: var(--color-group-one-black); */
   grid-column: 4;
}

[data-group*="Modulation"],
.input-color [class*="tribal"] {
   background: var(--color-group-one-5);
   color: var(--color-group-one-5-text);
   grid-column: 4;
}
[data-group*="SPACER-Modulation"] {
   color: var(--color-group-one-5);
   /* background: var(--color-group-one-black); */
   grid-column: 4;
}

.input-option-percussion_synth_sampler,
.input-option-midi_arpeggiator_effects,
.input-option-music_generator,
.input-option-general_modulation,
.input-option-subharmonic_synth,
.input-option-chorus_flanger_phaser_tremolo,
.input-option-vocal_specific_processing,
.input-option-glitch_stutter_and_granular_fx,
.UserAdded,
.input-container [data-group^="SPACER"] {
   grid-row-end: span 2;
   /* border-radius: 16.75px !important; */
}

[data-group*="Simulation"],
.input-color [class*="80_s"] {
   background: var(--color-group-one-6);
   color: var(--color-group-one-6-text);
   grid-column: 5;
}
[data-group*="SPACER-Simulation"] {
   color: var(--color-group-one-6);
   /* background: var(--color-group-one-black); */
   grid-column: 5;
}

[data-group*="CombinationTools"] {
   background: var(--color-group-one-8);
   color: var(--color-group-one-8-text);
   grid-column: 2;
}
[data-group*="SPACER-CombinationTools"] {
   color: var(--color-group-one-8);
   /* background: var(--color-group-one-black); */
   grid-column: 2;
}

[data-group*="Multi-FunctionShaper"],
.input-color [class*="vintage"] {
   background: var(--color-group-one-7);
   color: var(--color-group-one-7-text);
   grid-column: 2;
}
[data-group*="SPACER-Multi-FunctionShaper"] {
   color: var(--color-group-one-7);
   /* background: var(--color-group-one-black); */
   grid-column: 2;
}

[data-group*="Analyzers"],
.input-color [class*="latin"] {
   background: var(--color-group-one-10);
   color: var(--color-group-one-10-text);
   grid-column: 5;
}

[data-group*="SPACER-Analyzers"] {
   color: var(--color-group-one-10);
   /* background: var(--color-group-one-black); */
   grid-column: 5;
}

[data-group*="user-added-functions-container"] {
   display: flex;
   flex-wrap: wrap;
   min-height: fit-content;
   grid-column: 1 / -1;
   /* justify-content: center; */
   height: fit-content;
   align-items: center;
}

[data-group*="user-added-functions-container"] > label {
   flex-basis: 100%;
   color: var(--color-group-one-4);
}

[data-group*="user-added-functions-container"] > * {
   flex-grow: 1;
   min-height: 3em;
}

[data-group*="SPACER-user-added-functions"] {
   color: var(--color-group-one-4);
   /* background: var(--color-group-one-black); */
   grid-column: 1;
}
/* .Category {
  background: var(--iq-color-background-warm-gradient);
  color: var(--iq-color-foreground);
} */

/* .Category:before {
  content: "Category";
} */

/* .input-container .Dynamics {
  background: rgb(2, 218, 218);
  color: #1e1e1e;
} */
/* .Dynamics:before {
  content: "Dynamics";
} */
/* .input-container .FrequencyControl {
  background: rgb(218, 182, 2);
  color: #1e1e1e;
}
.FrequencyControl:before {
  content: "Frequency Control";
} */
/* .input-container .Saturation {
  background: rgb(218, 2, 2);
  color: #fefefe;
}
.Saturation:before {
  content: "Saturation";
} */
/* .input-container .TimeandSpace {
  background: rgb(102, 102, 102);
  color: #fefefe;
}
.TimeandSpace:before {
  content: "Time and Space";
} */

/* .Analyzers:before {
  content: "Analyzers";
} */

/* .input-container .CombinationTools {
  background: rgb(218, 142, 2);
  color: #222222;
}

.CombinationTools:before {
  content: "Combination Tools";
} */

.input-container .MIDI {
   background: rgb(218, 2, 142);
   color: #fefefe;
}

.MIDI:before {
   content: "MIDI";
}

/* .input-container .Modulation {
  background: rgb(52, 218, 2);
  color: #1e1e1e;
}

.Modulation:before {
  content: "Modulation";
} */

/* .input-container .Multi-FunctionShaper {
  background: rgb(2, 150, 218);
  color: #fefefe;
}

.Multi-FunctionShaper:before {
  content: "Multi-Function Shaper";
} */

/* .input-container .Simulation {
  background: rgb(168, 2, 218);
  color: #fefefe;
} */

/* .Simulation:before {
  content: "Simulation";
} */

.input-container .UserAdded,
.input-color [class*="asian"] {
   background: var(--color-group-one-4);
   color: var(--color-group-one-4-text);
}

div[class*="frequency_shifter_pitch_shifter"] > label {
   min-height: 5em !important;
   display: flex;
   justify-content: center;
   align-items: center;
}

.input-container .UserAdded {
   grid-column: 5;
   /* margin-top: 6em; */
   /* grid-column: 1 / -1; */
}

/* .UserAdded:before {
  content: 'User Added';
  font-variant: small-caps;
} */

/* .input-container .Tools {
  background: rgb(226, 224, 224);
  color: #222222;
}

.Tools:before {
  content: "Tools";
} */

/* IMAGE SELECTOR */
.image-selector-outer-container {
   display: flex;
   margin-left: 1em;
   flex-direction: row-reverse;
   flex-wrap: wrap-reverse;
   justify-content: flex-end;
   margin: 0;
   padding: 0;
   text-align: left;
}

.image-selector {
   position: fixed;
   background: #000000d6;
   display: flex;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 110;
   justify-content: center;
   align-items: flex-start;
   overflow: auto;
   padding: 50px 0 2em;
   border: 3px solid var(--iq-color-accent);
   border-radius: 50px;
   box-shadow:
      4px 4px 7px -4px white inset,
      -4px -4px 7px -4px #000000 inset;
}

.image-selector > div {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
}

.image-search-wrapper {
   flex-basis: 100%;
   display: flex;
   align-items: center;
   text-align: center;
   width: 100%;
}

.image-search-wrapper img {
   max-height: 10em;
   max-width: 75%;
   margin: 1em;
}

.image-selector .button-container {
   position: fixed;
   display: flex;
   flex-wrap: nowrap;
   top: 0;
   left: 50%;
   z-index: 10;
   width: 80%;
   background: #000000;
   margin: auto;
   transform: translateX(-50%);
   border-radius: 0 0 50px 50px;
   overflow: hidden;
}

.image-selector button {
   position: relative;
   top: 0;
   z-index: 10;
   min-height: 50px;
   font-size: 2em;
   width: 50%;
   border: none;
   box-shadow:
      inset 0 0 61px -3px #ffffff,
      0 0 61px -3px #ffffff8f,
      3px 3px 7px -3px black;
   background: radial-gradient(var(--iq-color-accent) 39%, #ffffffde);
   color: white;
   transition: 0.7s all ease;
   text-transform: uppercase;
   cursor: pointer;
}

.image-selector .select-button,
.image-selector .cancel-button {
   flex-grow: 1;
   border-radius: 0;
   margin: auto;
}

.image-selector .select-button {
   flex-basis: 75%;
   background: var(--iq-color-accent);
}

.image-selector .cancel-button {
   flex-basis: 25%;
   background: var(--iq-color-alert-red);
   opacity: 0.5;
   font-size: 1em;
}

.image-selector [class*="responsive"] {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   max-width: min-content;
   min-width: 170px;
   background: var(--iq-color-foreground);
   box-shadow:
      inset 0 -20px 50px -20px var(--iq-color-accent),
      inset 0 20px 20px -20px var(--iq-color-accent);
   color: var(--iq-color-accent);
   border-radius: 10px 10px;
   /* overflow: hidden; */
   padding: 0 0 1em;
   margin: 0.5em !important;
}

.image-selector [class*="responsive"] h4 {
   font-feature-settings: "smcp";
   /* background: var(--iq-color-accent); */
   /* box-shadow: inset 0 -30px 30px -20px #000, inset 0 30px 30px -20px #fff; */
   /* color: var(--iq-color-foreground); */
   font-variant: small-caps;
   line-height: 1.25em;
   margin: 0.5em auto;
   padding: 0 0.5em;
   border-radius: 10px;
   font-size: 1rem;
   text-shadow: 0 0 1px var(--iq-color-background);
   text-shadow: none;
   transform: scale(1, 1.25);
}

.image-selector [class*="responsive"] img {
   border-color: var(--iq-color-accent) !important;
   border-radius: 10px !important;
   border: none !important;
}

/* Hover turned Off for touch and stylus */
bodybody[class*="can-hover"]
   .input-container.input-functions
   input[type="checkbox"]:hover:after,
bodybody[class*="can-hover"]
   .input-container.input-functions
   input[type="checkbox"]:checked:hover:after,
bodybody[class*="can-hover"]
   .input-container.input-color
   input[type="checkbox"]:hover:after,
bodybody[class*="can-hover"]
   .input-container.input-color
   input[type="checkbox"]:checked:hover:after {
   border: none;
   box-shadow:
      inset 0 0 69px -30px hsl(0deg 0% 100%),
      0 0 21px -3px #ffffff94;
   background: radial-gradient(#ffffff, transparent);
   color: black !important;
   transform: none;
   min-width: auto;
   min-height: auto;
}
bodybody[class*="can-hover"]
   .input-container.input-functions
   input[type="checkbox"]:hover
   + label,
bodybody[class*="can-hover"]
   .input-container.input-color
   input[type="checkbox"]:hover
   + label {
   color: #00000069 !important;
   transform: scale(1, 1.125);
}
body[class*="can-hover"]
   .input-container.input-functions
   input[type="checkbox"]:hover
   + label,
body[class*="can-hover"]
   .input-container.input-color
   input[type="checkbox"]:hover
   + label {
   color: inherit;
   opacity: 1;
}
body[class*="can-hover"] .image-selector button:hover {
   opacity: 1;
   box-shadow: inset 0 0 20px 0 white;
   transition: 0.2s all ease;
}
body[class*="can-hover"] .image-selector .cancel-button:hover {
   background: var(--iq-color-alert-red);
}

/* /// Responsive Breakpoints /// */
@media screen and (min-width: 1500px) {
   .input-container input[type="checkbox"] + label {
      font-size: inherit;
   }
}

@media screen and (max-width: 600px) {
   .input-container.masterLibraryID:before {
      content: "*** Must match the title of the plugin file (VST or AU) ***";
   }

   body .input-container.input-container.input-functions {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      min-height: fit-content;
      max-height: none;
      height: max-content;
   }

   body .input-container.input-container.input-functions > * {
      flex-basis: 100% !important;
      max-height: 100% !important;
      position: relative !important;
      border-radius: 0.3px !important;
   }

   .input-container.input-functions input[type="checkbox"] + label,
   .input-container.input-color input[type="checkbox"] + label {
      min-height: 3em;
      display: flex;
      height: fit-content;
      justify-content: center;
      align-items: center;
      font-size: 1em;
   }

   .input-container.masterLibraryID:before {
      bottom: 0;
      max-width: 90%;
      min-width: 0;
      left: 50%;
      transform: translate(-50%, -50%);
   }
}
