/* .form-group-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  border-radius: inherit;
  overflow: hidden;
  margin: 1em;
  box-shadow: inset -3px -3px 10px -7px white, inset 3px 3px 10px -7px black;
  padding: 1em 0em 3em;
} */

.form-group-wrap {
   display: grid;
   flex-wrap: wrap;
   justify-content: space-evenly;
   border-radius: inherit;
   overflow: hidden;
   margin: auto;
   /* box-shadow: inset -3px -3px 10px -7px white, inset 3px 3px 10px -7px black; */
   padding: 1em 0em 3em;
   width: 100%;
   grid-columns: (auto, 1fr);
   grid-template-columns: 25% 25% 25% 25%;
   grid-template-areas:
      "close-button close-button close-button close-button"
      "name name company company"
      "productURL productURL productURL productURL"
      "functions functions functions functions"
      "color color precision precision "
      "photoURL photoURL photoURL photoURL"
      "status rating oversampling favorite"
      "notes notes notes notes"
      "identifier identifier identifier identifier"
      "masterLibraryID masterLibraryID masterLibraryID masterLibraryID"
      "_id _id _id _id";
   margin-top: 10em;
   position: relative;
   box-shadow: var(--apo-form-outer-shadow);
   border: 1px solid;
   border-top: var(--apo-form-top-border);
   background: var(--iq-add-tool-forms-background);
   color: var(--iq-add-tool-forms-color);
}

body[class*="largeForms"] .form-group-wrap {
   background: var(--iq-add-tool-forms-background);
   grid-template-areas:
      "close-button close-button close-button close-button"
      "name name name name"
      "productURL productURL productURL productURL"
      "functions functions functions functions"
      "precision precision precision precision"
      "color color color color"
      "company company company company"
      "photoURL photoURL photoURL photoURL"
      "status rating oversampling favorite"
      "notes notes notes notes"
      "identifier identifier identifier identifier"
      "masterLibraryID masterLibraryID masterLibraryID masterLibraryID"
      "_id _id _id _id";
}

.form-group-wrap:first-child {
   margin-top: 3em;
}

.form-group-wrap:after {
   content: "";
   position: absolute;
   display: block;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   /* background: red; */
   box-shadow:
      inset 0px 10px 11px -10px var(--iq-color-accent-dark),
      inset 0px 0px 50px -11px var(--iq-color-accent-dark);
   pointer-events: none;
}

.form-group-wrap > [class*="input-container"] {
   position: relative;
   /* padding: 0 1em; */
   margin-top: auto;
}

body:not([class*="largeForms"]) .form-group-wrap > [class*="input-container"] {
   margin-bottom: -0.5em;
   padding-bottom: 2em;
}

.form-group-wrap > [class*="input-container"]:after {
   content: "";
   position: absolute;
   display: block;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   box-shadow: var(--apo-form-gradient);
   pointer-events: none;
   border-radius: 11px;
}

.form-group-wrap > [class*="input-container"]:not([class*="FormInput_name"]) {
   margin: 0.5em auto 1em;
}

body[class*="largeForms"]
   .form-group-wrap
   > [class*="input-container"]:not([class*="FormInput_name"]) {
   margin: 0.5em auto;
}

[class*="FormInput_name"] {
   grid-area: name;
}

[class*="FormInput_input-functions"] {
   grid-area: functions;
}

body:not([class*="largeForms"]) [class*="FormInput_input-functions"] > label {
   /* padding: 0; */
   position: relative;
   box-shadow: none !important;
   transform: none;
}

[class*="FormInput_input-precision"] {
   grid-area: precision;
}
[class*="FormInput_input-color"] {
   grid-area: color;
}
[class*="FormInput_input-company"] {
   grid-area: company;
}
[class*="FormInput_notes"] {
   grid-area: notes;
}
[class*="FormInput_productURL"] {
   grid-area: productURL;
   margin: 0 !important;
}

body[class*="largeForms"] [class*="FormInput_productURL"] {
   padding: 0 !important;
}

[class*="FormInput_photoURL"] {
   grid-area: photoURL;
}

[class*="FormInput_productURL"] input,
[class*="FormInput_productURL"] [class*="label-name"] {
   display: none;
}

[class*="FormInput_productURL"] a {
   text-align: center;
   width: 100%;
}

body [class*="FormInput_input-oversampling"] {
   grid-area: oversampling;
   min-width: 0;
   text-overflow: ellipsis;
   max-width: 100%;
   border-radius: 0;
}

/* body [class*='FormInput_input-oversampling']:hover:before {
  content: 'Oversampling';
  position: absolute;
  top: 1em;
  display: block;
  left: 50%;
  background: linear-gradient(
    0deg,
    var(--iq-color-background) 60%,
    transparent
  );
  height: 2em;
  width: 90%;
  min-width: fit-content;
  padding: 0.25em 0 0 0;
  z-index: 2;
  font-size: 1em;
  margin: auto;
  margin-top: -0.5em;
  transform: translate(-50%, 2.5%);
} */

body [class*="FormInput_input-oversampling"] > label {
   text-overflow: ellipsis;
   max-width: 100%;
   overflow: hidden;
   /* border-radius: 0; */
   line-height: 1.25em;
}

body [class*="FormInput_input-oversampling"] > label:hover {
   max-width: max-content;
   z-index: 2;
   border-radius: 50px 50px 0 0;
   border: 1px outset gray;
   border-bottom: none;
}

[class*="FormInput_input-favorite"] {
   grid-area: favorite;
}
[class*="FormInput_input-rating"] {
   grid-area: rating;
}
[class*="FormInput_input-status"] {
   grid-area: status;
}
[class*="FormInput_identifier"] {
   grid-area: identifier;
}
body [class*="FormInput_id"] {
   display: none;
}
[class*="FormInput__id"] {
   grid-area: _id;
}
[class*="FormInput_masterLibraryID"] {
   grid-area: masterLibraryID;
}
/* .form-group-wrap > * > *:not(label) {
  min-height: 100%;
} */

.form-question-container,
.form-answer-container {
   flex-basis: 100%;
}

/* .close-item-form-button {
   position: absolute;
   top: 30px;
   left: 4em;
   background: transparent;
   color: inherit;
   border: none;
   font-size: 12px;
   font-weight: 700;
   transform: scale(1.25, 1);
   transition: all 0.2s ease;
} */

.close-item-form-button {
   position: relative;
   display: block;
   grid-area: close-button;
   width: 100%;
   /* max-width: 100%; */
   /* min-height: 100%; */
   height: 3em;
   left: 0;
   margin: 0em auto;
   /* transform: none; */
   text-transform: uppercase;
   opacity: 0.75;
   font-size: 0.75em;
   font-weight: 700;
   box-shadow:
      3px 3px 7px -4px rgba(255, 255, 255, 0.8588235294) inset,
      -3px -3px 7px -4px black inset;
   padding: 0 1em;
   border-radius: 6.125px;
   background: var(--iq-color-accent-2);
   color: var(--iq-color-foreground);
   border: none;
   transform: scale(1) translateY(-50%);
   transition: 0.1s allease;
   cursor: pointer;
   z-index: 3;
}

.close-item-form-button:after {
   content: "Cancel this form?";
   position: absolute;
   top: 0;
   left: 0;
   width: 10em;
   padding: 1em;
   border-radius: 25px;
   box-shadow:
      2px 2px 3px -2px rgba(0, 0, 0, 0.502),
      inset 3px 3px 3px -2px rgb(255, 255, 255),
      inset -3px -3px 3px -2px rgba(0, 0, 0, 0.502);
   background: var(--iq-color-background-warm-gradient);
   color: var(--iq-color-foreground);
   transform: scale(0);
   transition: 0.3s all ease;
   transform-origin: 1em 1em;
}

/* Hover turned Off for touch and stylus */
body[class*="can-hover"] .close-item-form-button:hover:after {
   /* content: "Cancel this Form?";
  position: absolute;
  top: 1em;
  left: 1.25em;
  padding: 0.5em;
  border-radius: 0 25px 25px 25px;
  box-shadow: 2px 2px 3px -2px rgba(0, 0, 0, 0.502),
    inset 3px 3px 3px -2px rgb(255, 255, 255),
    inset -3px -3px 3px -2px rgba(0, 0, 0, 0.502);
  background: var(--iq-color-background-warm);
  color: var(--iq-color-foreground);
  transform: scale(0.5) translate(-17%, -48%);
  transition: 0.2s all ease; */
}

body[class*="can-hover"]
   div[class*="editing-fields-wrap"]
   .close-item-form-button:hover {
   transform: scale(1, 1.25) translate(-50%, 0%) !important;
}

body[class*="can-hover"] .close-item-form-button:hover {
   transform: scale(1.25) translateY(-37.5%);
   background: var(--iq-color-accent-2);
   color: var(--iq-color-background-contrast);
   opacity: 1;
}

body[class*="can-hover"]body[class*="largeForms"]
   .close-item-form-button:hover {
   transform: scale(1.25) !important;
   background: var(--iq-color-accent-2);
   color: var(--iq-color-background-contrast);
   opacity: 1;
}

.scroll-wrap {
   overflow: scroll;
   max-height: calc(93% - 72px);
   position: relative;
   padding: 0 1em;
   margin: 0 auto 2%;
   box-shadow: 0px 25px 30px -25px #494949;
   border-radius: 50px;
   background: var(--iq-add-tool-background);
   color: var(--apo-add-tool-color);
   font-weight: 700;
}

div[class*="editing-fields-wrap"] .scroll-wrap {
   border-radius: 0 0 50px 50px;
}

/* .scroll-wrap > .form-group-wrap {
  margin-top: 8em;
} */

@media screen and (max-width: 600px) {
   .form-group-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
   }
}
