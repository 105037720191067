.app-container {
   text-align: center;
   background: var(--iq-color-foreground);
   background: var(--iq-color-background-body);
   background-image: var(--apo-background-image);
   background-image: var(--apo-background-image-body);
   color: var(--iq-color-background-warm);
}

/* .content-container {
  display: grid;
    grid-template-areas:
        "filter toolsrows"
        "filter toolsrows"
        "g h i";
} */
/* Scroll bar style from my Codepen: https://codepen.io/DarkMG73/pen/LMXMaE?editors=0100 */
/*
 *  STYLE 3
 */
::-webkit-scrollbar-track {
   background: transparent;
   background: var(--iq-color-scrollbar-track);
   border-radius: 50px;
   /* box-shadow: -3px -3px 5px -3px inset rgb(59 59 59),
    -2px -3px 5px -3px inset rgb(255 255 255); */
   margin-top: 50px;
}

::-webkit-scrollbar {
   width: auto;
   height: 100%;
   min-width: 8px;
   border-radius: 50px;
   background-color: transparent;
   /* background: radial-gradient(
    ellipse at center,
    rgb(87 87 87) 0%,
    rgb(9 9 9) 100%
  ); */
   background: transparent;
}

/* ::-webkit-scrollbar-thumb {
  background: radial-gradient(
    ellipse at center,
    var(--iq-color-accent) 0%,
    rgba(0, 0, 0, 1) 100%
  );

  border-radius: 50px;
} */

::-webkit-scrollbar-thumb {
   background: radial-gradient(
      ellipse at center,
      var(--iq-color-scrollbar-thumb-inner) 0%,
      var(--iq-color-scrollbar-thumb-outer) 100%
   );

   border-radius: 50px;
}

@media (prefers-reduced-motion: no-preference) {
   .App-logo {
      animation: App-logo-spin infinite 20s linear;
   }
}

.App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
}

.App-link {
   color: #61dafb;
}

@keyframes App-logo-spin {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}

.content-container {
   z-index: 1;
}

.admin-notice {
   background: var(--iq-color-accent);
   border-radius: 50px 50px 0 0;
   top: 50%;
   color: var(--iq-color-background-contrast);
   font: var(--iq--font-heading);
   right: 0;
   max-height: 2em;
   max-width: 800px;
   padding: 0.25em;
   position: fixed;
   text-align: center;
   transform: translate(-0.5em, -8em) rotate(-90deg);
   transform-origin: right;
   width: 21em;
   z-index: 2000;
}

.admin-notice > button {
   position: absolute;
   top: 0;
   left: 50%;
   transform: translate(-50%, -100%);
   padding: 0.25em 1em;
   border-radius: 50px 50px 0 0;
}

.admin-notice h3 {
   margin: 0;
}

.error-wrapper {
   border-radius: 50px 50px 0 0;
   position: fixed;
   bottom: 0;
   left: 50%;
   transform: translate(-50%, 100%) scale(1, 0);
   z-index: 100000;
   transition: 0.3s all ease;
   transform-origin: bottom;
   transition-timing-function: cubic-bezier(0.02, 1.03, 0.36, 5);
   max-height: 100%;
   max-width: 100%;
   width: 100%;
}

.error-wrapper > * {
   max-width: 100%;
   overflow-y: auto;
}

.error-active {
   transform: translate(-50%, 0) scale(1, 1);
   max-width: 100%;
   min-width: 0;
}

.error-close-button {
   position: absolute;
   right: 24px;
   top: 24px;
   background: transparent;
   border: none;
   color: inherit;
   text-shadow: 0 0 1px var(--iq-color-accent);
   font-size: 18px;
}

.loader-wrap {
   position: fixed;
   top: 37%;
   right: 0;
   width: 250px;
   height: 55px;
   z-index: 2000;
   border-radius: 50px 0 0 50px;
   background: var(--iq-color-foreground);
   color: var(--iq-color-background-warm);
   box-shadow:
      inset 3px 3px 11px -7px,
      7px 7px 10px -7px black;
}

.loader-wrap div {
   max-height: 100%;
   top: 50%;
   min-height: 100%;
   transform: translateY(-50%);
   border-radius: 0;
   position: relative;
   padding: 0;
   margin: 0 auto;
   height: 100%;
   background: transparent;
   box-shadow: none;
   overflow: visible;
   border-radius: inherit;
}

.loader-wrap div span {
   max-height: 100%;
   height: 20px;
   top: calc(50% - 10px);
   display: flex;
   background: var(--iq-color-background-warm-gradient);
}

.loader-wrap div span {
}
.loader-wrap div span:nth-child(even) {
}

/* DayPilot Modals Theme */
:global(body .modal_default_main) {
   background: var(--iq-color-foreground) !important;
   color: var(--iq-color-background) !important;
   border-radius: 50px;
   border: none;
   box-shadow:
      3px 3px 7px -4px white inset,
      -3px -3px 7px -4px black inset,
      0 0 30px 0 var(--iq-color-accent-light),
      0 0 7px 2px var(--iq-color-accent) inset;
   margin: auto !important;
   position: fixed !important;
   top: 50% !important;
   transform: translate(-50%, -50%);
   border: none !important;
}

:global(.modal_default_main .modal_default_inner) {
   padding: 2em 3em;
}

:global(.modal_default_buttons) {
   display: flex;
   justify-content: center;
   overflow: hidden;
   border-radius: 50px;
}

:global(.modal_default_main) button:hover {
   /* color: var(--iq-color-background);
  background: var(--iq-color-accent-light); */
   transform: scale(1.125, 1);
   z-index: 1;
}
:global(.modal_default_main) button {
   box-shadow:
      3px 3px 7px -4px white inset,
      -3px -3px 7px -4px black inset;
   background: var(--iq-color-accent);
   color: var(--iq-color-foreground);
   border-radius: 0;
   margin: 0;
   padding: 1em;
   flex-grow: 1;
   border: none;
   transition: 0.2s all;
   cursor: pointer;
}

:global(.modal_default_content) {
   padding: 10px 0px;
   max-height: 61vh;
   overflow: auto;
}

:global(.modal_default_main .modal_default_cancel) {
   background: var(--iq-color-alert-red);
   color: var(--iq-color-alert-text);
}
:global(.modal_default_main .modal_default_cancel:hover) {
   /* background: var(--iq-color-accent-2-light);
  color: var(--iq-color-background); */
}
