.loader-wrap {
   position: relative;
   width: 100%;
   /* min-width: 700px;
  min-height: 110vh; */
   max-height: 700px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 10px;
   /* border: 1px inset; */
   width: 100%;
   border-radius: 10px;
   box-shadow:
      0 0 50px -3px #ffffff,
      inset 2px 2px 10px -4px #ffffff47;
   box-shadow:
      inset 3px 3px 20px -7px #000000,
      inset -3px -3px 20px -7px white;
   padding: 100px 30px;
   box-sizing: border-box;
   overflow: hidden;
   background: var(--iq-color-background-warm-gradient);
}

.loader-wrap:before {
   position: absolute;
   content: "";
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   opacity: 0.5;
   background: var(--iq-color-foreground-gradient);
   background-image: url(https://www.transparenttextures.com/patterns/real-carbon-fibre.png);
   border-radius: inherit;
   background-size: 16px;
   /* background-repeat: repeat; */
   /* background-image: url(https://www.transparenttextures.com/patterns/brushed-alum.png);*/
}

.loader {
   position: relative;
}

/*** Loader 2 ***/

@keyframes loader2 {
   0%,
   50%,
   100% {
      transform: scale(1);
   }
   60% {
      transform: scale(2);
      box-shadow: 0 0 20px var(--iq-color-accent-light);
   }
}
.loader2 {
   width: 200px;
   height: 100px;
}

.loader2 span {
   width: 20px;
   height: 80%;
   border-radius: 20px;
   position: absolute;
   top: 0;
}

.loader2 span:nth-child(1) {
   left: 0;
   background-color: var(--iq-color-background);
   box-shadow: 0 0 20px var(--iq-color-accent);
   animation: loader2 3s ease infinite;
}

.loader2 span:nth-child(2) {
   left: 25px;
   background-color: var(--iq-color-foreground);
   box-shadow: 0 0 20px var(--iq-color-accent);
   animation: loader2 3s ease infinite 0.15s;
}

.loader2 span:nth-child(3) {
   left: 50px;
   background-color: var(--iq-color-background);
   box-shadow: 0 0 20px var(--iq-color-accent);
   animation: loader2 3s ease infinite 0.3s;
}

.loader2 span:nth-child(4) {
   left: 75px;
   background-color: var(--iq-color-foreground);
   box-shadow: 0 0 20px var(--iq-color-accent);
   animation: loader2 3s ease infinite 0.6s;
}
.loader2 span:nth-child(5) {
   left: 100px;
   background-color: var(--iq-color-background);
   box-shadow: 0 0 20px var(--iq-color-accent);
   animation: loader2 3s ease infinite 0.75s;
}

.loader2 span:nth-child(6) {
   left: 125px;
   background-color: var(--iq-color-foreground);
   box-shadow: 0 0 20px var(--iq-color-accent);
   animation: loader2 3s ease infinite 0.9s;
}
.loader2 span:nth-child(7) {
   left: 150px;
   background-color: var(--iq-color-background);
   box-shadow: 0 0 20px var(--iq-color-accent);
   animation: loader2 3s ease infinite 1.05s;
}

.loader2 span:nth-child(8) {
   left: 175px;
   background-color: var(--iq-color-foreground);
   box-shadow: 0 0 20px var(--iq-color-accent);
   animation: loader2 3s ease infinite 1.2s;
}
