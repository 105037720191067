.edit-buttons-wrap {
   position: relative;
   display: block;
   border: 0;
   margin: 0 auto;
   max-width: 80%;
   z-index: 11;
   padding-right: 34px;
   min-height: max-content;
   height: 107px;
   background: transparent;
   padding-top: 27px;
   box-shadow: 0px 40px 31px -46px #000000;
}

.inner-wrap {
   position: relative;
   height: 100%;
}

.inner-wrap input[type="submit"]:hover {
   opacity: 1 !important;
   /* transform: translate(-50%, calc(-100% - 1em)) scale(1.1) !important; */
   transform: scale(1.125) !important;
}

.inner-wrap input[data-data="delete-button"]:hover {
   transform: translateY(-100%) scale(1.125) !important;
   box-shadow: 0 0 20px -5px var(--iq-color-accent-2) !important;
}

body div[class*="editing-fields-wrap"] [data-data="larger-forms-1"],
body div[class*="editing-fields-wrap"] [data-data="add-tool-submit-2-wrap"] {
   display: none;
}

body div[class*="editing-fields-wrap"] [class*="close-item-form-button"] {
   position: fixed !important;
   top: 12em;
   max-width: 100%;
   left: 50%;
   transform: translateX(-50%);
   opacity: 1;
   background: var(--iq-color-accent-2);
   border-radius: 3.125px;
   z-index: 11;
   letter-spacing: 0.5em;
}

.error-text {
   border-bottom: 14px inset orangered !important;
   border-top: 14px inset orangered !important;
   color: orangered !important;
   font-size: 1.5em;
   margin: 1em auto 0;
}

[data-data="add-tool-submit-2-wrap"] {
   position: sticky !important;
   top: 0;
   z-index: 10;
}
