.tools-rows-list-container {
   display: flex;
   flex-wrap: wrap;
   /* max-height: 10em; */
   /* overflow-x: hidden; */
   padding: 0 2em;
}

.history-section-title {
   text-transform: uppercase;
   font-variant: small-caps;
   /* font-size: 1.75em; */
   /* background: var(--iq-color-background-warm); */
   /* color: var(--iq-color-foreground); */
   font-weight: 700;
   letter-spacing: var(--iq-spacing-subheading);
   box-shadow:
      inset 0 8px 15px -13px rgb(0 0 0 / 30%),
      0 -8px 15px -16px white;
   margin: 4em 0 1em;
   padding: 1.5em 0 0.5em;
   border-radius: 10px 10px 0 0;
   text-shadow:
      -1px -1px 1px rgb(0 0 0 / 50%),
      1px 1px 1px rgb(255 255 255 / 50%);
}

.tools-rows-list-container > div {
   width: 100%;
   max-width: 100%;
   min-height: max-content;
   margin: 0;
   flex-basis: 23%;
   border-radius: 50px;
   margin: 1%;
   /* overflow: hidden; */
   flex-grow: 1;
   min-width: min-content;
}

.tool-row-list-container > div {
   box-shadow:
      inset 0px -4px 4px rgb(0 0 0 / 38%),
      inset 0px 4px 4px #e4e4e4;
}

.open-all-button-wrap {
   position: sticky;
   display: flex;
   flex-basis: 100% !important;
   top: 50px;
   z-index: 50;
   transition: all 0.2s ease;
   margin: 1em auto !important;
}

.open-all-button-wrap button {
   transition: all 0.7s ease;
}

/* Hover turned Off for touch and stylus */
body[class*="can-hover"] .open-all-button-wrap button:hover {
   box-shadow: inset 0 0 30px -15px;
   color: var(--iq-color-foreground);
   background: var(--iq-color-accent-2);
   text-shadow: 0 0 3px rgba(255, 255, 0, 0.504);
   transition: all 0.3s ease;
}

.section-subtitle {
   font: var(--iq-font-subheading);
   letter-spacing: var(--iq-spacing-heading);
   text-align: center;
   margin: 1.5em auto;
   flex-grow: 1;
   min-width: min-content;
   min-width: 100%;
}

.no-plugin-title {
   font: var(--iq-font-subheading);
   color: var(--iq-color-foreground);
}

.no-plugin-text {
   max-width: 500px;
   margin: 0 auto 3em;
   margin: 0 auto 3em;
   color: var(--iq-color-background);
   background: var(--iq-color-foreground-gradient);
   padding: 1.5em 3em;
   border-radius: 50px;
}

.tools-rows-list-container .filters-data-container,
.filters-list-container {
   position: relative;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   width: 100%;
   margin: auto;
   padding: 0;
   flex-basis: 100%;
}

.filters-list-container {
   margin: -1em auto 2.5em;
}

.filtered-list-title {
   width: 100%;
   letter-spacing: 0.25em;
   font-variant: small-caps;
}

.filters-list-container .topic-list {
   display: flex;
   flex-direction: column;
   font-variant: all-small-caps;
   padding: 0;
   margin: auto 1em;
   list-style: none;
}

.filters-list-container .topic-title {
   margin: 0;
   border-bottom: 1px solid;
   opacity: 0.5;
}

.filters-list-container .name {
   margin: 0 auto;
   color: var(--iq-color-accent-2);
   font-weight: 700;
}

.amount-after-filters {
   font-weight: 700;
   transform: scale(1.5) translate(0, -12.5%);
   display: inline-block;
   margin-right: 1em;
}

body .tools-rows-list-container .display-button-container {
   position: relative;
   width: 80%;
   flex-basis: 100%;
   display: flex;
   flex-direction: column;
}

.display-button-container .display-section-title {
   position: relative;
   letter-spacing: var(--iq-spacing-subheading);
   font-size: calc(0.8rem + 0.2vw);
   font-variant: all-small-caps;
   text-align: center;
   /* opacity: 0.5; */
   width: 100%;
   padding: 0;
   margin: auto auto 0.125em;
}

body .tools-rows-list-container .display-button-container .display-button-wrap {
   position: relative;
   width: 80%;
   flex-basis: 100%;
   flex-wrap: wrap;
   display: flex;
   overflow: hidden;
   border-radius: 50px;
   padding: 0;
   max-width: 700px;
   flex-grow: 1;
   margin: auto;
   min-width: min-content;
}

body .tools-rows-list-container .display-button-wrap button {
   border-radius: 0;
   min-width: min-content;
   flex-grow: 1;
   flex-basis: 33.333%;
}

@media screen and (max-width: 800px) {
   body .tools-rows-list-container .display-button-wrap button {
      min-height: 2.5em;
      display: flex;
      align-items: center;
   }
}

@media screen and (max-width: 600px) {
   body
      .tools-rows-list-container
      .display-button-container
      .display-button-wrap {
      box-shadow: 0 0 10px 0 var(--iq-color-accent);
      border: 1px solid var(--iq-color-accent);
   }
   .tools-rows-list-container > div {
      width: 100%;
      max-width: 100%;
      min-height: max-content;
      margin: 0;
      flex-basis: 23%;
      border-radius: 50px;
      margin: 1%;
      /* overflow: hidden; */
      flex-grow: 1;
      min-width: 100%;
      max-width: 100%;
   }
   body .tools-rows-list-container .display-button-container {
      margin: auto auto 1em;
   }

   body .tools-rows-list-container .display-button-wrap button {
      border-radius: 0;
      min-width: min-content;
      flex-grow: 1;
      flex-basis: 50%;
      min-height: 2.5em;
      display: flex;
      align-items: center;
   }
}
